//@ts-nocheck
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { BiRuble } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

import { useGetBillingClientByIdMutation } from '../../api/billingUserApi'
import { downloadDocumentFile } from '../../api/billingUtils'
import { useGetDemoCabinetUrlMutation, useGetUserOPDataMutation } from '../../api/demoCabinetUrl'
import { useGetDocsByIDMutation } from '../../api/documentsApi'
import { useGetPresentationMutation } from '../../api/getPresentationApi'
import { useGetAddonPackagesMutation } from '../../api/tariffsApi'
import { useLoginTrackerMutation } from '../../api/trackerApi'
import { useGetShowcaseAutoInfoMutation } from '../../api/userApi'
import { useSendUserClickEventMutation } from '../../api/userClickEventsApi'
import arrowNext from '../../assets/icons/arrow-next.svg'
import arrowUpWhite from '../../assets/icons/arrowUpWhite.svg'
import disabledModules from '../../assets/icons/disabledModules.svg'
import earth from '../../assets/icons/earth.svg'
import errorImg from '../../assets/icons/error.svg'
import fileCheck from '../../assets/icons/fileCheck.svg'
import iInCircle, { ReactComponent as IInCircle } from '../../assets/icons/iInCircle.svg'
import iInCircleBlue from '../../assets/icons/iInCircleBlue.svg'
import onePlatform from '../../assets/icons/onePlatform.svg'
import pencil from '../../assets/icons/pencil.svg'
import smartContract from '../../assets/icons/smartContract.svg'
import get_presentation from '../../assets/images/get_presentation.svg'
import { Container, RedText } from '../../assets/styles/global'
import { Analytics } from '../../components/analytics'
import { Card } from '../../components/card'
import { CardBalance } from '../../components/cardBalance'
import { Balance, CardBalanceWrap } from '../../components/cardBalance/style'
import { ChatWidget } from '../../components/chatWidget'
import { ClientPackages } from '../../components/clientPackages'
import { EditAddress } from '../../components/form/EditAddress'
import { Invoicing } from '../../components/invoicing/Invoicing'
import { ONE_PLATFORM, SHOWCASE_AUTO, SMART_CONTRACT } from '../../constants/global'
import { selectCurrentProduct, setCurrentProduct } from '../../store/currentProductSlice'
import { selectProductFrom } from '../../store/redirectSlice'
import { selectUserBilling } from '../../store/userBillingSlice'
import { setGlobalDownloadHomePage } from '../../store/userBillingSlice'
import { selectUser } from '../../store/userSlice'
import { Button } from '../../ui/button'
import { Image } from '../../ui/image'
import { Notification } from '../../ui/notification/Notification'
import { DoublePopup } from '../../ui/popup/DoublePopup'
import { Popup } from '../../ui/popup/Popup'
import { ResponsePopup } from '../../ui/popup/responsePopup/ResponsePopup'
import { DoublePopupItem, ImageModal, List, Text, TextDoublePopup, TitlePopup } from '../../ui/popup/style'
import { convertDate } from '../../utils/convertDate'
import { downloadFile, setFileDocumentName } from '../../utils/fileHandler'
import { getNoun } from '../../utils/getNoun'
import { getLocalStorageValue } from '../../utils/localStorageHelpers'
import { numberDaysBeforeShutdown } from '../../utils/numberDaysBeforeShutdown'
import { numberWithSpaces } from '../../utils/numberWithSpaces'
import { ContainerTable, ContainerTableEl, Group } from '../documentation/style'
import { ClientPackagesWrapper, NotificationListClients, Wrapper } from '../settings/style'
import { Block, FieldInner, Notification as NotificationSettings } from '../settings/style'
import {
  LicenseContainer,
  LicenseGroup,
  SmallText,
  SubTitle,
  TariffsCard,
  TariffsTitle,
  TextPrice,
  TooltipGroup
} from '../tariffs/style'
import infoIcon from './../../assets/icons/i.svg'
import {
  ContainerProduct,
  LicenseMore,
  MainInfoBlock,
  MainInfoCard,
  MainInfoContainer,
  MainInfoTitle,
  ProductContainer,
  ProductWrapper,
  Slide,
  Title
} from './style'

export const Home = () => {
  /*TODO временные переменные с данными ,удалить как появятся данные с бэка*/
  const list = [
    {
      text: 'Кол-во проданных авто:',
      value: '600 шт'
    },
    {
      text: 'Кол-во предоплат:',
      value: `456 шт`
    }
  ]
  const TIME = 3600000
  const user = useSelector(selectUser)
  const curProduct = useSelector(selectCurrentProduct)
  const redirect = useSelector(selectProductFrom)
  const { listClients, testAB, billingSelectedDealer, billingClientInfo, isGlobalDownloadHomePage } =
    useSelector(selectUserBilling)
  const dispatch = useDispatch()
  const [sendUserClickEvent] = useSendUserClickEventMutation()
  const userType = getLocalStorageValue('user')?.attributes?.type
  const currentTime = new Date()
  const [
    loginTracker,
    {
      isLoading: loginTrackerIsLoadind,
      data: loginTrackerData,
      isError: loginTrackerIsError,
      error: loginTrackerError,
      isSuccess: loginTrackerisSuccess
    }
  ] = useLoginTrackerMutation()
  const navigate = useNavigate()

  const [getAddonPackages, { data: addonPackages }] = useGetAddonPackagesMutation()
  const [getDocsByID, { data: documents }] = useGetDocsByIDMutation()
  const [isDownload, setIsDownload] = useState(false)

  const [filter, setFilter] = useState<FilterDocuments | {}>({})

  const [currentEnv, setCurrentEnv] = useState<string>('')

  useEffect(() => {
    window.location.href.includes('test.lk.udpauto') ? setCurrentEnv('test') : setCurrentEnv('prod')
  }, [currentEnv])

  const setOPLink = () => {
    return currentEnv === 'test'
      ? `https://test.oneplatform.site/#/?udp_token=${encodeURIComponent(
          user?.attributes?.one_platform_data?.auth_key
        )}&email=${encodeURIComponent(user?.attributes?.email)}`
      : `https://oneplatform.site/#/?udp_token=${encodeURIComponent(
          user?.attributes?.one_platform_data?.auth_key
        )}&email=${encodeURIComponent(user?.attributes?.email)}`
  }

  const setSCLink = () => {
    return currentEnv === 'test'
      ? `https://app.sc-yc-test.kodixauto.ru/#/external_auth/?udp_token=${encodeURIComponent(
          user?.attributes?.smart_contract_data?.auth_key
        )}&email=${encodeURIComponent(user?.attributes?.email)}`
      : `https://app.smart-contract.digital/#/external_auth/?udp_token=${encodeURIComponent(
          user?.attributes?.smart_contract_data?.auth_key
        )}&email=${encodeURIComponent(user?.attributes?.email)}`
  }

  useEffect(() => {
    setFilter({
      type: 5,
      from: new Date('2023-11-01 00:00:00'.replace(/\s+/g, 'T').concat('.000+08:00')).toISOString(),
      to: new Date().toISOString()
    })
  }, [])

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          left: 'auto',
          right: '-5rem',
          borderRadius: '50% 50% 0 0',
          width: '4rem',
          height: '5rem',
          bottom: 'calc(50% - 3.7rem)',
          transform: 'rotate(-180deg)',
          top: 'auto',
          background: `#003B6A`,
          backgroundSize: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        onClick={onClick}
      >
        <Image
          src={arrowUpWhite}
          alt="arrow"
          style={{
            width: '100%',
            height: '100%'
          }}
        />
      </div>
    )
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          left: 'auto',
          right: '-5rem',
          width: '4rem',
          height: '5rem',
          top: 'calc(50% - 3.7rem)',
          borderRadius: '50% 50% 0 0',
          background: `#003B6A`,
          backgroundSize: '100%'
        }}
        onClick={onClick}
      >
        <Image
          src={arrowUpWhite}
          alt="arrow"
          style={{
            width: '100%',
            height: '100%'
          }}
        />
      </div>
    )
  }

  const [billingClientDataForCard, setbillingClientDataForCard] = useState<any>([])
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false)
  const [isShowDemoPopup, setIsShowDemoPopup] = useState<boolean>(false)
  const [isErrorDemo, setIsErrorDemo] = useState<boolean>(false)
  const [product, setProduct] = useState<string>('')
  const [isShowPopupSuccess, setIsShowPopupSuccess] = useState<boolean>(false)
  const [isInvoicing, setIsInvoicing] = useState<boolean>(false)
  const [isShowInvoicingSuccess, setIsShowInvoicingSuccess] = useState<boolean>(false)
  const [isRecommendedPaymentAmount, setIsRecommendedPaymentAmount] = useState<boolean>(false)
  const [isReading, setIsReading] = useState<boolean>(false) //временный стейт

  const docTypes = ['Счет', 'Акт', 'Лицензии', 'Учредительные документы', 'Акт сверки', 'УПД']

  const { billingSelectedDealerId } = useSelector(selectUserBilling)

  const [isMobile, setIsMobile] = useState<boolean>(false)

  const [editAddressData, setEditAddressData] = useState<any>({})
  const [isLoading, setLoader] = useState<boolean>(false)

  const [currentPeriod, setCurrentPeriod] = useState<number>(0)

  const [getPresentation, { data, isSuccess, isError }] = useGetPresentationMutation()
  const [
    getDemoCabinetUrl,
    { data: demoCabinetData, isSuccess: demoCabinetSuccess, isLoading: demoCabinetLoading, isError: demoCabinetError }
  ] = useGetDemoCabinetUrlMutation()
  const [
    getUserOPData,
    {
      data: demoCabinetOPData,
      isSuccess: demoCabinetOPSuccess,
      isLoading: demoCabinetOPLoading,
      isError: demoCabinetOPError
    }
  ] = useGetUserOPDataMutation()
  const [getShowcaseAutoInfo, { data: dataShowcaseAuto }] = useGetShowcaseAutoInfoMutation()
  const [isDisablingModules, setIsDisablingModules] = useState<boolean>(false)
  const [isShowPopupEditAddress, setIsShowPopupEditAddress] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      if (billingSelectedDealer?.id) {
        await getAddonPackages({ id: billingSelectedDealer.id })
      }
    })()
  }, [billingSelectedDealer])

  useEffect(() => {
    ;(async () => {
      if (billingSelectedDealer?.id && !!Object.keys(filter).length) {
        const params = new URLSearchParams()

        params.append('filter', JSON.stringify(filter))

        await getDocsByID({
          id: billingSelectedDealer.id,
          params: params.toString()
        })
      }
    })()
  }, [billingSelectedDealer, filter])

  useEffect(() => {
    setbillingClientDataForCard([
      {
        isPositive: billingClientInfo?.balance?.payment && Number(billingClientInfo?.balance?.payment) > 0,
        text: 'Текущий статус',
        value: !billingClientInfo ? (
          '-'
        ) : billingClientInfo?.balance?.paid ? (
          `Оплачено до ${
            (billingClientInfo?.balance?.next_payment_date &&
              format(new Date(billingClientInfo?.balance?.next_payment_date), 'dd.MM.yy')) ||
            '−'
          }`
        ) : !(Number(billingClientInfo?.balance?.payment) > 0) && !billingClientInfo?.balance?.paid ? (
          'Оплата не требуется'
        ) : (
          <RedText
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsDisablingModules(true)
              if (userType !== 'internal') {
                sendUserClickEvent('button_recommended_amount_click')
              }
            }}
          >
            Отключение модулей
          </RedText>
        )
      },
      {
        text: 'Следующий платеж без учета НДС',
        value: billingClientInfo?.balance?.payment
          ? numberWithSpaces(Number(billingClientInfo.balance.payment).toFixed(2))
          : '-',
        rub: true,
        link: false
      },
      {
        text: 'Следующий платеж с учетом НДС',
        value: billingClientInfo?.balance?.recommend_payment
          ? numberWithSpaces(Number(billingClientInfo.balance.recommend_payment).toFixed(2))
          : '−',
        rub: true,
        link: true
      }
    ])
  }, [billingClientInfo])

  useEffect(() => {
    if (isSuccess) {
      setIsShowPopup(false)
      setIsShowDemoPopup(false)
      setIsShowPopupSuccess(true)
    }
  }, [isSuccess, data])

  useEffect(() => {
    const redirectToSmartContract = () => {
      const authKey = user?.attributes?.smart_contract_data?.auth_key
      const email = user?.attributes?.email

      if (authKey) {
        window.location.href = `https://app.smart-contract.digital/#/external_auth?udp_token=${encodeURIComponent(
          authKey
        )}&email=${encodeURIComponent(email)}`
      } else {
        setIsShowPopup(true)
      }
    }

    const redirectToOnePlatform = () => {
      const authKey = user?.attributes?.one_platform_data?.auth_key
      const email = user?.attributes?.email
      if (authKey) {
        window.location.href = `https://oneplatform.site/#/?udp_token=${encodeURIComponent(
          authKey
        )}&email=${encodeURIComponent(email)}`
      } else {
        setIsShowPopup(true)
      }
    }

    switch (redirect) {
      case 'sc':
        redirectToSmartContract()
        break
      case 'op':
        redirectToOnePlatform()
        break
      default:
        break
    }
  }, [redirect, user])

  useEffect(() => {
    ;(async () => {
      if (billingSelectedDealerId) {
        await getBillingClientById({ id: billingSelectedDealerId })
      }
    })()
  }, [billingSelectedDealerId])

  useEffect(() => {
    const timeInLocalStorage = getLocalStorageValue('time')
    /*если в локалсторадже ничего нет, и сумма на счету отрицательная, то показываем модалку*/
    if (!timeInLocalStorage && billingClientInfo?.balance?.total && Number(billingClientInfo?.balance?.total) < 0) {
      setIsDisablingModules(true)
    }
    /*Если в хранилище что-то есть и сумма отрицательная, то анализируем и показываем или не показываем модалку*/
    if (
      timeInLocalStorage &&
      timeInLocalStorage.length > 0 &&
      billingClientInfo?.balance?.total &&
      Number(billingClientInfo?.balance?.total) < 0
    ) {
      const foundElem: any = timeInLocalStorage.find((item: { dealer_id: string; date: Date }) => {
        return item.dealer_id === billingSelectedDealerId
      })
      /*если в хранилищи нет элемента, то показываем модалку*/
      if (!foundElem) setIsDisablingModules(true)
      /*если в хранилище есть элемент*/
      if (foundElem) {
        const timeForSelectedDealer = new Date(foundElem.date)
        /*берем его дату закрытия, добавляем 1час и сравниваем с текущим временем, если меньше, то показываем модалку*/
        if (timeForSelectedDealer.getTime() + TIME < currentTime.getTime()) {
          setIsDisablingModules(true)
        }
      }
    }
  }, [billingClientInfo?.balance?.total])
  useEffect(() => {
    const interval = setInterval(() => {
      const timeInLocalStorage = getLocalStorageValue('time')
      if (Number(billingClientInfo?.balance?.total) < 0 && !isDisablingModules) {
        const currentTime = new Date()
        if (timeInLocalStorage && timeInLocalStorage.length > 0) {
          const foundElem: any = timeInLocalStorage.find((item: { dealer_id: string; date: Date }) => {
            return item.dealer_id === billingSelectedDealerId
          })
          /*если в хранилище есть элемент*/
          if (foundElem) {
            const timeForSelectedDealer = new Date(foundElem.date)
            /*берем его дату закрытия, добавляем 1час и сравниваем с текущим временем, если меньше, то показываем модалку*/
            if (timeForSelectedDealer.getTime() + TIME < currentTime.getTime()) {
              setIsDisablingModules(true)
            }
          }
        }
      }
    }, 5000)

    if (isDisablingModules || (billingClientInfo?.balance?.total && Number(billingClientInfo?.balance?.total) > 0)) {
      clearInterval(interval)
    }
    return () => {
      clearInterval(interval)
    }
  }, [isDisablingModules, billingClientInfo?.balance?.total])

  useEffect(() => {
    setTimeout(() => {
      dispatch(setGlobalDownloadHomePage(true))
    }, 1000)
    if (window.innerWidth < 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [])

  const handleClick = (product_name: string, client_id: number) => {
    getPresentation({
      data: {
        id: '',
        type: 'request_presentation',
        attributes: {
          client_id,
          product_name
        }
      }
    })
  }

  const downloadDocument = async (id: number, report_id?: number, name: string, date: string) => {
    setLoader(true)

    const blobData = await downloadDocumentFile({
      id: billingSelectedDealer.id,
      file_id: id,
      report_id: report_id ? report_id : null
    })

    setLoader(false)

    downloadFile(blobData, setFileDocumentName(name, date))
  }

  const openDemoSessionUrl = () => {
    switch (product) {
      case SMART_CONTRACT:
        handleSmartContract()
          .then((res) => {
            handleRedirect(res?.attributes?.url)
          })
          .catch((error) => {
            console.error('Ошибка при получении URL:', error)
            setIsErrorDemo(true)
          })
        break
      case ONE_PLATFORM:
      case SHOWCASE_AUTO:
        handleOnePlatformOrShowcaseAuto()
          .then((res) => {
            if (res?.attributes?.one_platform_data?.auth_key) {
              openOnePlatformUrl()
            }
          })
          .catch((error) => {
            console.error('Ошибка при получении URL:', error)
            setIsErrorDemo(true)
          })
        break
      default:
        console.error('Неизвестный продукт')
    }
  }

  const goToTrackePageHelper = async (url: string) => {
    const create_window = window.open()
    const res = await loginTracker({ destination: url }).unwrap()
    const create_url = `https://${res?.attributes?.host}/external_auth/?token=${encodeURIComponent(
      res?.attributes?.token
    )}&back_url=${res?.attributes?.back_url}`

    if (res?.attributes && create_window) {
      create_window.location = create_url
    }
    //TODO: можно допилить всплытие попапап с какой-то ошибкой при переходе в трекер
    return
  }

  const settingsAddons = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    dots: true,
    slidesToScroll: 1
  }

  const handleSmartContract = async () => {
    const url = '/smart_contract/demo_session/start'
    const result = await getDemoCabinetUrl({ url }).unwrap()
    // @ts-ignore
    if (!result) {
      throw new Error('Ошибка при обработке Smart Contract')
    }

    return result
  }

  const handleOnePlatformOrShowcaseAuto = async () => {
    const base_url = '/oneplatform/demo_session/start'
    const url = product === SHOWCASE_AUTO ? `${base_url}/with_available_cars` : base_url
    const result = await getUserOPData({ url }).unwrap()
    // @ts-ignore
    if (!result) {
      throw new Error('Ошибка при обработке OnePlatform или ShowcaseAuto')
    }

    return result
  }

  const handleRedirect = (url: string) => {
    if (url) {
      window.location.href = url
    }
  }

  const openOnePlatformUrl = () => {
    const authKey = user?.attributes?.one_platform_data?.auth_key
    const email = user?.attributes?.email

    if (authKey && email) {
      window.location.href = `https://oneplatform.site/#/?udp_token=${encodeURIComponent(
        authKey
      )}&email=${encodeURIComponent(email)}`
    } else {
      console.error('Не удалось получить данные для аутентификации на OnePlatform')
    }
  }

  const [
    getBillingClientById,
    {
      isLoading: isLoadingBillingUserById,
      data: billingClientById,
      isError: isErrorBillingUserById,
      error: billingUserByIdError,
      isSuccess: isSuccessBillingUserById
    }
  ] = useGetBillingClientByIdMutation()

  useEffect(() => {
    setEditAddressData({
      ...billingClientById?.billing_address,
      email: billingClientById?.email
    })
  }, [billingClientById])

  return (
    <Container isFixed={false}>
      {(!listClients || !listClients.length || !billingClientInfo) &&
        isGlobalDownloadHomePage && ( //Если список клиентов не пришел то показываем это сообщение
          <NotificationListClients>
            <IInCircle />В вашем личном кабинете нет закрепленного лицевого счета. Обратитесь к вашему Аккаунт менеджеру
            для его получения.
          </NotificationListClients>
        )}
      {/*Модалка презентации*/}
      {isShowDemoPopup && (
        <DoublePopup closePopup={setIsShowDemoPopup} background={true}>
          <>
            <DoublePopupItem textAlign={'center'}>
              <ImageModal>
                <Image src={earth} alt="presentation" />
              </ImageModal>
              <TitlePopup>
                Познакомиться <br /> с демо-кабинетом
              </TitlePopup>
              {isError ? (
                <TextDoublePopup>Что-то пошло не так, попробуйте еще раз</TextDoublePopup>
              ) : (
                <TextDoublePopup>
                  Посмотреть на продукт изнутри <br /> без менеджера. Этот этап поможет вам быстрее <br /> ознакомиться
                  с функциями
                  {/* <SubTextDoublePopup>Длительность сессии 30 минут</SubTextDoublePopup> */}
                </TextDoublePopup>
              )}
              <Button
                onClick={() => {
                  openDemoSessionUrl()
                  if (curProduct.currentProduct.product_name === 'Платформа сайтов') {
                    if (userType !== 'internal') {
                      sendUserClickEvent('click_one_platform_start_demo')
                    }
                  } else {
                    if (userType !== 'internal') {
                      sendUserClickEvent('click_smart_contract_start_demo')
                    }
                  }
                }}
                disabled={isLoading}
              >
                Попробовать самостоятельно
              </Button>
            </DoublePopupItem>
            <DoublePopupItem textAlign={'center'}>
              <ImageModal>
                <Image src={get_presentation} alt="presentation" />
              </ImageModal>
              <TitlePopup>
                Получить презентацию <br /> от менеджера
              </TitlePopup>
              {isError ? (
                <Text>Что-то пошло не так, попробуйте еще раз</Text>
              ) : (
                <Text>
                  Наш специалист подробно расскажет о продукте, ответит на все ваши вопросы. Сразу сможет подключить и
                  отключить его
                </Text>
              )}
              <Button
                onClick={() => {
                  handleClick(curProduct.currentProduct.product_name, curProduct.currentProduct.client_id)
                  if (curProduct.currentProduct.product_name === 'Платформа сайтов') {
                    if (userType !== 'internal') {
                      sendUserClickEvent('click_one_platform_request_presentation')
                    }
                  } else {
                    if (userType !== 'internal') {
                      sendUserClickEvent('click_smart_contract_request_presentation')
                    }
                  }
                }}
                disabled={isLoading}
              >
                Получить презентацию
              </Button>
            </DoublePopupItem>
          </>
        </DoublePopup>
      )}
      {/*Success Модалка презентации*/}
      {isShowPopupSuccess && (
        <Popup closePopup={setIsShowPopupSuccess} background={true}>
          <ResponsePopup
            setIsShowPopup={setIsShowPopupSuccess}
            status={'success'}
            text={
              'Заявка отправлена вашему аккаунт менеджеру. В ближайшее время он с вами свяжется для проведения презентации'
            }
          />
        </Popup>
      )}
      {/*Модалка выставления счета*/}
      {isInvoicing && (
        <Popup
          closePopup={() => {
            setIsInvoicing(false)
            setCurrentPeriod(0)
          }}
          background={true}
        >
          <Invoicing
            billingClientInfoById={billingClientInfo}
            setIsInvoicingSuccess={setIsShowInvoicingSuccess}
            setIsInvoicing={setIsInvoicing}
            period={currentPeriod}
          />
        </Popup>
      )}
      {/*TODO: Добавить номер счета и почту, на которую был отправлен счет*/}
      {/*Success Модалка выставления счета*/}
      {isShowInvoicingSuccess && (
        <Popup closePopup={setIsShowInvoicingSuccess} background={true}>
          <ResponsePopup
            setIsShowPopup={setIsShowInvoicingSuccess}
            successText={'Счет на оплату успешно создан! Счет отправлен на эл. адрес, указанный в настройках.'}
            errorText={'Ошибка создания счета!'}
          />
        </Popup>
      )}
      {/*Рекомендуемая сумма платежа*/}
      {isRecommendedPaymentAmount && (
        <Popup closePopup={setIsRecommendedPaymentAmount} background={true}>
          <TitlePopup size={'small'}>Рекомендуемая сумма платежа</TitlePopup>
          <Text>Сумма вашей задолженности и сумма следующего платежа</Text>
          <ClientPackages
            billingClientBalance={billingClientInfo.balance}
            billingSelectedDealerId={billingSelectedDealerId}
            totalAmountOn={true}
          ></ClientPackages>
          <Button
            onClick={() => {
              setIsRecommendedPaymentAmount(false)
              setIsInvoicing(true)
              if (userType !== 'internal') {
                sendUserClickEvent('button_top_up_account_click')
              }
              setCurrentPeriod(1)
            }}
            disabled={isLoading}
          >
            Пополнить счет
          </Button>
        </Popup>
      )}
      {/*Отключение модулей*/}
      {isDisablingModules && (
        <Popup closePopup={setIsDisablingModules} background={true} isDanger={true}>
          <ImageModal>
            <Image src={disabledModules} alt="disabledModules" />
          </ImageModal>
          <TitlePopup size={'small'}>
            Запущен процесс <br /> отключения модулей
          </TitlePopup>
          <Text>
            На вашем лицевом счете <RedText> отрицательный баланс.</RedText> Наши сервисы работают по предоплате
            согласно{' '}
            <a href={`${process.env.REACT_APP_TRACKER_HOST}/agreement/`} target={'_blank'} rel="noreferrer">
              п.4.7 - 4.9 лицензионного соглашения.
            </a>
          </Text>
          <Notification icon={iInCircle} className={'full'}>
            Обращаем ваше внимание на то, что восстановление работы сайта будет платным.
          </Notification>
          <List>
            <li>После возникновения задолженности:</li>
            <li>
              через{' '}
              <span>
                {numberDaysBeforeShutdown().forms_disabled}{' '}
                {getNoun(numberDaysBeforeShutdown().forms_disabled, 'день', 'дня', 'дней')}
              </span>
              , контакты и формы на вашем сайте автоматически будут скрыты.
            </li>
            <li>
              через{' '}
              <span>
                {numberDaysBeforeShutdown().site_disabled}{' '}
                {getNoun(numberDaysBeforeShutdown().site_disabled, 'день', 'дня', 'дней')}
              </span>
              , ваш сайт будет автоматически отключен.
            </li>
            <li>
              через{' '}
              <span>
                {numberDaysBeforeShutdown().site_deleted}{' '}
                {getNoun(numberDaysBeforeShutdown().site_deleted, 'день', 'дня', 'дней')}
              </span>
              , ваш сайт будет удален полностью автоматически.
            </li>
          </List>
          <Button
            onClick={() => {
              setIsDisablingModules(false)
              setIsInvoicing(true)
              if (userType !== 'internal') {
                sendUserClickEvent('button_top_up_account_click')
              }
            }}
            disabled={isLoading}
          >
            Пополнить счет
          </Button>
        </Popup>
      )}

      {/*Ошибка перехода в демокабинет*/}
      {isErrorDemo && (
        <Popup closePopup={setIsErrorDemo} background={true} isDanger={true} textAlign={'center'}>
          <ImageModal>
            <Image src={errorImg} alt="error" />
          </ImageModal>
          <TitlePopup>
            {product === 'Платформа сайтов' ? 'Приносим свои извинения' : 'Ошибка перехода в демо-кабинет'}
          </TitlePopup>
          <Text>
            {product === 'Платформа сайтов'
              ? 'В данный момент ведутся технические работы и данный функционал временно не доступен'
              : 'Пожалуйста, повторите попытку позднее.'}
          </Text>
          <Button
            onClick={() => {
              setIsErrorDemo(false)
            }}
            disabled={isLoading}
          >
            Вернуться в профиль
          </Button>
        </Popup>
      )}

      <ContainerProduct>
        {listClients && !!listClients.length && billingClientInfo && (
          <CardBalanceWrap>
            <Analytics />
            <Balance>
              <CardBalance list={list} value={502000} title={'Выручка'} icon={iInCircleBlue} isDevelop={true} />
              <CardBalance
                list={billingClientDataForCard}
                value={
                  billingClientInfo?.balance?.total ? parseFloat(billingClientInfo?.balance?.total)?.toFixed(2) : '−'
                }
                title={'Баланс'}
                isBtn={true}
                handleClick={setIsInvoicing}
                openPopup={setIsRecommendedPaymentAmount}
                showPopupWarning={setIsDisablingModules}
              />
            </Balance>
          </CardBalanceWrap>
        )}

        {testAB === '3' && billingSelectedDealer && billingClientInfo ? (
          <LicenseContainer>
            <TariffsTitle>Дополнительные пакеты лицензий</TariffsTitle>

            {isMobile ? (
              <LicenseGroup>
                {addonPackages?.length ? (
                  <Slider {...settingsAddons} className="slider-home">
                    {addonPackages?.map((pack: any) => (
                      <TariffsCard className="license" key={pack.id}>
                        <SubTitle className="license-title short-title">
                          {pack.name}
                          <TooltipGroup className="inline">
                            <p>{pack.description}</p>
                            <img src={infoIcon} alt="" />
                          </TooltipGroup>
                        </SubTitle>
                        <SmallText>стоимость за 1 период</SmallText>
                        <TextPrice className="license-text">
                          {Number(pack.price_per_period).toLocaleString('ru')} <BiRuble />
                        </TextPrice>
                        <SmallText>
                          *стоимость подключения {Number(pack.price_per_period).toLocaleString('ru')}&nbsp;
                          <BiRuble />
                        </SmallText>
                        <Button
                          variant={
                            !!billingSelectedDealer.plan.addon_package_ids.find((addon) => addon === pack.id)
                              ? 'default'
                              : 'secondary'
                          }
                          size="medium"
                          onClick={() => navigate('/tariffs')}
                        >
                          {!!billingSelectedDealer.plan.addon_package_ids.find((addon) => addon === pack.id)
                            ? 'Активен'
                            : 'Подключить'}
                        </Button>
                      </TariffsCard>
                    ))}
                  </Slider>
                ) : null}
              </LicenseGroup>
            ) : (
              <LicenseGroup>
                {addonPackages?.map((pack: any) => (
                  <TariffsCard className="license" key={pack.id}>
                    <SubTitle className="license-title short-title">
                      {pack.name}&nbsp;
                      <TooltipGroup className="inline">
                        <p>{pack.description}</p>
                        <img src={infoIcon} alt="" />
                      </TooltipGroup>
                    </SubTitle>
                    <SmallText>стоимость за 1 период</SmallText>
                    <TextPrice className="license-text">
                      {Number(pack.price_per_period).toLocaleString('ru')} <BiRuble />
                    </TextPrice>
                    <SmallText>
                      *стоимость подключения {Number(pack.price_per_period).toLocaleString('ru')}&nbsp;
                      <BiRuble />
                    </SmallText>
                    <Button
                      variant={
                        !!billingSelectedDealer.plan.addon_package_ids.find((addon) => addon === pack.id)
                          ? 'default'
                          : 'secondary'
                      }
                      size="medium"
                      onClick={() => navigate('/tariffs')}
                    >
                      <span>
                        {!!billingSelectedDealer.plan.addon_package_ids.find((addon) => addon === pack.id)
                          ? 'Активен'
                          : 'Подключить'}
                      </span>
                    </Button>
                  </TariffsCard>
                ))}
              </LicenseGroup>
            )}
            <LicenseMore onClick={() => navigate('/tariffs')}>
              Показать еще
              <Image src={arrowNext} alt="Далее" />
            </LicenseMore>
          </LicenseContainer>
        ) : null}

        {testAB === '0' || testAB === '1' || testAB === '2' ? (
          <MainInfoContainer>
            {isShowPopupEditAddress && (
              <Popup closePopup={() => setIsShowPopupEditAddress(false)}>
                <TitlePopup>Адрес доставки</TitlePopup>
                <EditAddress
                  id={billingClientById?.id}
                  formData={editAddressData}
                  onClose={async () => {
                    await getBillingClientById({ id: billingClientById?.id.toString() || '' })
                    setIsShowPopupEditAddress(false)
                  }}
                  isEdo={Boolean(billingClientById?.is_edo)}
                />
              </Popup>
            )}
            <MainInfoBlock>
              <MainInfoCard>
                <MainInfoTitle>Связь с техподдержкой</MainInfoTitle>
                <Button
                  variant="secondary"
                  onClick={async () => {
                    await goToTrackePageHelper('/tasks/')
                  }}
                >
                  Посмотреть
                </Button>
              </MainInfoCard>
              <MainInfoCard>
                <MainInfoTitle>Документы</MainInfoTitle>

                <ContainerTable className="header" bg="rgba(226, 228, 235, 1)">
                  <ContainerTableEl color="rgba(34, 51, 97, 1)" bold={true} fontSize="14px" width="35%">
                    Наименование
                  </ContainerTableEl>
                  <ContainerTableEl color="rgba(34, 51, 97, 1)" bold={true} fontSize="14px" width="20%">
                    Дата
                  </ContainerTableEl>
                  <ContainerTableEl color="rgba(34, 51, 97, 1)" bold={true} fontSize="14px" width="20%">
                    Сумма
                  </ContainerTableEl>
                </ContainerTable>

                {documents?.items?.map((document, index) => {
                  if (index < 2) {
                    return (
                      <div key={document.id}>
                        <ContainerTable className="desktop home-documents">
                          <ContainerTableEl width="35%">{`${docTypes[document.typeNum]} ${
                            document.name
                          }`}</ContainerTableEl>
                          <ContainerTableEl width="20%">{convertDate(document.dt, '.')}</ContainerTableEl>
                          <ContainerTableEl width="20%">
                            {document.sum} <BiRuble />{' '}
                          </ContainerTableEl>
                          <ContainerTableEl className="home-table" width="25%" alignItems="flex-end">
                            <Button
                              disabled={isLoading}
                              size="medium"
                              onClick={() =>
                                downloadDocument(
                                  document.id,
                                  document?.idReport,
                                  document.name,
                                  convertDate(document.dt, '-')
                                )
                              }
                            >
                              Скачать
                            </Button>
                          </ContainerTableEl>
                        </ContainerTable>

                        <ContainerTable key={document.id} className="mobile">
                          <Group>
                            <ContainerTableEl color="rgba(34, 51, 97, 1)" bold={true} fontSize="14px">
                              Наименование
                            </ContainerTableEl>
                            <ContainerTableEl fontSize="14px">{`${docTypes[document.typeNum]} ${
                              document.name
                            }`}</ContainerTableEl>
                          </Group>
                          <Group>
                            <ContainerTableEl color="rgba(34, 51, 97, 1)" bold={true} fontSize="14px">
                              Дата
                            </ContainerTableEl>
                            <ContainerTableEl fontSize="14px">{convertDate(document.dt, '.')}</ContainerTableEl>
                          </Group>
                          <Group>
                            <ContainerTableEl color="rgba(34, 51, 97, 1)" bold={true} fontSize="14px">
                              Сумма
                            </ContainerTableEl>
                            <ContainerTableEl fontSize="14px">
                              {document.sum} <BiRuble />
                            </ContainerTableEl>
                          </Group>
                          <ContainerTableEl className="home-table" alignItems="center" style={{ marginTop: '20px' }}>
                            <Button
                              disabled={isLoading}
                              size="medium"
                              onClick={() =>
                                downloadDocument(
                                  document.id,
                                  document?.idReport,
                                  document.name,
                                  convertDate(document.dt, '-')
                                )
                              }
                            >
                              Скачать
                            </Button>
                          </ContainerTableEl>
                        </ContainerTable>
                      </div>
                    )
                  }

                  return null
                })}
              </MainInfoCard>
            </MainInfoBlock>
            <MainInfoBlock>
              <FieldInner className="home">
                <MainInfoTitle>
                  Адрес доставки
                  <Image src={pencil} alt="pencil" onClick={() => setIsShowPopupEditAddress(true)} />
                </MainInfoTitle>
                <NotificationSettings isGreenText={billingClientById?.is_edo}>
                  <Image src={fileCheck} alt="fileCheck" />
                  {billingClientById?.is_edo
                    ? 'Электронные версии документов направляются через ЭДО'
                    : 'На этот адрес будут отправлены оригиналы документов'}
                </NotificationSettings>
                <Block>
                  <SubTitle>Название организации</SubTitle>
                  <div>{billingClientById?.billing_address?.name || '-'}</div>
                </Block>
                <Block>
                  <Block>
                    <SubTitle>Получатель</SubTitle>
                    <div>{billingClientById?.billing_address?.receiver || '-'}</div>
                  </Block>
                </Block>
                <Block>
                  <SubTitle>Адрес</SubTitle>
                  <div>{billingClientById?.billing_address?.address || '-'}</div>
                </Block>
                {billingClientById?.is_forward_client && (
                  <Block>
                    <SubTitle>E-mail для уведомлений и документов</SubTitle>
                    <div>{billingClientById?.email || '-'}</div>
                  </Block>
                )}
              </FieldInner>
            </MainInfoBlock>
          </MainInfoContainer>
        ) : null}

        <div>
          <Title>Продукты</Title>
          <ProductWrapper>
            <ProductContainer>
              <Slide>
                <Card
                  title={ONE_PLATFORM}
                  activeEvent={'click_one_platform_active'}
                  openPopup={() => {
                    dispatch(
                      setCurrentProduct({
                        product_name: ONE_PLATFORM,
                        client_id: billingSelectedDealerId ? billingSelectedDealerId : 0
                      })
                    )
                    // setIsShowPopup(true) // Убрать когда появится демокабинет
                    setIsShowDemoPopup(true) // Раскоментить, когда появится демокабинет
                    setProduct(ONE_PLATFORM)
                    if (userType !== 'internal') {
                      sendUserClickEvent('click_one_platform')
                    }
                  }}
                  text={'Конструктор и платформа сайтов для дилерского центра'}
                  connected={{
                    active: !!user.attributes?.one_platform_data?.auth_key,
                    text: 'Посетители уже переходят на ваш сайт',
                    errorText: 'Вы теряете посетителей'
                  }}
                  link={user?.attributes?.one_platform_data?.auth_key ? setOPLink() : ''}
                  icon={onePlatform}
                  id={'oneplatform'}
                />
                <Card
                  title={SMART_CONTRACT}
                  activeEvent={'click_smart_contract_active'}
                  openPopup={() => {
                    dispatch(
                      setCurrentProduct({
                        product_name: SMART_CONTRACT,
                        client_id: billingSelectedDealerId ? billingSelectedDealerId : 0
                      })
                    )
                    // setIsShowPopup(true) // Убрать когда появится демокабинет
                    setIsShowDemoPopup(true) // Раскоментить, когда появится демокабинет
                    setProduct(SMART_CONTRACT)
                    if (userType !== 'internal') {
                      sendUserClickEvent('click_smart_contract')
                    }
                  }}
                  text={'Цифровая сделка для продаж авто онлайн'}
                  connected={{
                    active: !!user?.attributes?.smart_contract_data?.auth_token,
                    text: 'Вы получаете заявки',
                    errorText: 'Вашим покупателям сложно с вами связаться'
                  }}
                  link={user?.attributes?.smart_contract_data?.auth_token ? setSCLink() : ''}
                  icon={smartContract}
                  id={'home_card_3'}
                />
              </Slide>
            </ProductContainer>
          </ProductWrapper>
        </div>
      </ContainerProduct>
      <ChatWidget />
    </Container>
  )
}
